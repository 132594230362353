const strapiConfig = {
  apiURL: process.env.API_BASE_URL,
  accessToken: process.env.API_TOKEN,
  collectionTypes: [
    {
      singularName: 'toolkit-module',
      queryParams: {
        populate: {
          Title: '*',
          slug: '*',
          keywords: "*",
          facilitator_guide: '*',
          presentation: '*',
          powerpoint: '*',
          full_module: '*',
          thumbnail: '*'
        },
      },
    },
    {
      singularName: 'topic',
      queryParams: {
        publicationState:
          process.env.GATSBY_IS_PREVIEW === 'true' ? 'preview' : 'live',
        populate: {
          thumbnail: '*',
          keywords: "*",
          hero_image: {
            populate: '*',
          },
          blocks: {
            populate: '*',
          },
          resources: {
            populate: '*',
          },
          videos_and_webinars: {
            populate: '*',
          },
          series: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'resource',
      queryParams: {
        publicationState:
          process.env.GATSBY_IS_PREVIEW === 'true' ? 'preview' : 'live',
        populate: {
          thumbnail: '*',
          keywords: "*",
          pdf: '*',
          category: {
            populate: '*',
          },
          topics: {
            populate: '*',
          },
          external_resource: '*',
          external_url_resource: '*',
          external_file_size: '*',
          open_external_in_new_window: '*',
          series: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'video',
      queryParams: {
        publicationState:
          process.env.GATSBY_IS_PREVIEW === 'true' ? 'preview' : 'live',
        populate: {
          strapi_id: '*',
          thumbnail: '*',
          transcript: '*',
          title: '*',
          slug: '*',
          author: '*',
          external_resource: '*',
          displayOnVideosWebinarsPage: "*",
          video_length: '*',
          keywords: "*",
          presenters: {
            populate: '*',
          },
          body: {
            populate: '*',
          },
          date: {
            populate: '*',
          },
          video_category: {
            populate: '*',
          },
          topics: {
            populate: '*',
          },
          category: {
            populate: '*',
          },
          series: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'category',
    },
    {
      singularName: 'video-category',
    },
    {
      singularName: 'serie',
      queryParams: {
        publicationState:
          process.env.GATSBY_IS_PREVIEW === 'true' ? 'preview' : 'live',
        populate: {
          thumbnail: '*',
          keywords: "*",
          notes: "*",
          hero_image: {
            populate: '*',
          },
          category: {
            populate: '*',
          },
          topics: {
            populate: '*',
          },
          resources: {
            populate: '*',
          },
          videos_webinars_series: {
            populate: '*',
          },
        },
      },
    },
  ],
  singleTypes: [
    {
      singularName: 'home',
      queryParams: {
        populate: {
          title: "*",
          description: "*",
          keywords: "*",
          hero_image: {
            populate: '*',
          },
          blocks: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'about',
      queryParams: {
        populate: {
          keywords: "*",
          hero_image: {
            populate: '*',
          },
          blocks: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: "federal-sponsor",
      queryParams: {
        populate: {
          title: "*",
          hero_image: {
            populate: "*",
          },
          description: "*",
          keywords: "*",
          blocks: {
            populate: "*",
          },
        },
      },
    },
    {
      singularName: "contractor-acknowledgments-page",
      queryParams: {
        populate: {
          title: "*",
          hero_image: {
            populate: "*",
          },
          description: "*",
          keywords: "*",
          blocks: {
            populate: "*",
          },
        },
      },
    },
    {
      singularName: "explore-topic",
      queryParams: {
        populate: {
          hero_image: {
            populate: '*',
          },
          description: "*",
          keywords: "*",
          blocks: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'videopage',
      queryParams: {
        populate: {
          title: '*',
          description: '*',
          keywords: "*",
          slug: '*',
          hero_image: {
            populate: '*',
          },
          blocks: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: "idta-page",
      queryParams: {
        populate: {
          title: "*",
          slug: "*",
          keywords: "*",
          hero_image: {
            populate: "*",
          },
          blocks: {
            populate: "*",
          },
          text: {
            populate: "*",
          },
          resources: {
            populate: "*"
          },
          videos_and_webinars: {
            populate: '*',
          },
          series: {
            populate: '*',
          },
          image: "*",
          idta_files: "*",
        },
      },
    },
    {
      singularName: "rpg-program-page",
      queryParams: {
        populate: {
          title: "*",
          slug: "*",
          keywords: "*",
          hero_image: {
            populate: "*",
          },
          blocks: {
            populate: "*",
          },
          resources: {
            populate: "*"
          },
          videos_and_webinars: {
            populate: '*',
          },
          text: {
            populate: "*",
          },
          series: {
            populate: '*',
          },
          image: "*",
          rpg_files: "*",
        },
      },
    },
    {
      singularName: "key-legislation",
      queryParams: {
        populate: {
          title: "*",
          slug: "*",
          description: "*",
          keywords: "*",
          hero_image: {
            populate: "*",
          },
          blocks: {
            populate: "*",
          },
          resources: {
            populate: "*"
          },
          videos_and_webinars: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: "explore-topic",
      queryParams: {
        populate: {
          title: "*",
          description: "*",
          keywords: "*",
          slug: "*",
          hero_image: {
            populate: "*",
          }
        },
      },
    },
    {
      singularName: "technical-assistance-page",
      queryParams: {
        populate: {
          title: "*",
          slug: "*",
          keywords: "*",
          hero_image: {
            populate: "*",
          },
          text: {
            populate: "*",
          },
          resources: {
            populate: "*"
          },
          image: "*",
          videos_and_webinars: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: "statistics-page",
      queryParams: {
        populate: {
          title: "*",
          description: "*",
          keywords: "*",
          slug: "*",
          hero_image: {
            populate: "*",
          },
          text: {
            populate: "*",
          },
          resources: {
            populate: "*"
          }
        },
      },
    },
    {
      singularName: "global",
      queryParams: {
        populate: {
          favicon: '*',
          defaultSeo: {
            populate: '*',
          },
        },
      },
    },
    {
      singularName: 'training-tool-kit',
      queryParams: {
        populate: {
          hero_image: {
            populate: '*',
          },
          keywords: "*",
          resources: "*",
          glossary: "*",
          full_toolkit: "*"
        },
      },
    },
  ],
};

module.exports = {
  trailingSlash: 'always',
  siteMetadata: {
    siteUrl: 'https://ncsacw.acf.hhs.gov/',
    author: 'National Center on Substance Abuse and Child Welfare',
    title: 'National Center on Substance Abuse and Child Welfare',
    description:
      'NCSACW is a national resource center providing information, expert consultation, training and technical assistance to child welfare, dependency court and substance use treatment professionals to improve the safety, permanency, well-being and recovery outcomes for children, parents and families.',
    navigation: [
      {
        title: 'Home',
        link: '/',
        items: [{ text: 'Home', link: '/' }],
      },
      {
        title: 'Explore Topics',
        link: '/topics',
        items: [
          {
            text: 'Children and Families Affected by Parental Substance Use Disorders (SUDs)',
            link: '/',
          },
          { text: 'Drug Testing in Child Welfare', link: '/' },
          { text: 'Engagement and Retention', link: '/' },
          { text: 'Family-Centered Approach', link: '/' },
          { text: 'Family First Prevention Services Act', link: '/' },
          { text: 'Family Treatment Courts', link: '/' },
          { text: 'Medication for Substance Use Disorders', link: '/' },
          { text: 'Methamphetamine', link: '/' },
          { text: 'Neonatal Abstinence Syndrome', link: '/' },
          { text: 'Peer and Recovery Specialist Support', link: '/' },
          { text: 'Plans of Safe Care', link: '/' },
          { text: 'Preventing Child Abuse & Neglect', link: '/' },
          { text: 'Screening and Assessment', link: '/' },
          {
            text: 'Spotlight on Disproportionality and Disparities among Families in Child Welfare and Substance Use Treatment',
            link: '/',
          },
          { text: 'Supporting Families Affected by Opioids', link: '/' },
          {
            text: 'Supporting Families During a Public Health Crisis',
            link: '/',
          },
          { text: 'Supporting Pregnant & Postpartum Women', link: '/' },
          { text: 'Trauma-Informed Care', link: '/' },
          { text: 'Tribal Community Resources', link: '/' },
          { text: 'Supporting Pregnant & Postpartum Women', link: '/' },
        ],
      },
      {
        title: 'Research',
        link: '/research',
        items: [
          { text: 'Child Welfare and Substance Use Disorder Treatment Statistics', link: '/research/child-welfare-statistics' },
          { text: 'Interactive Bibliography Search', link: '/research/bibliography' },
          { text: 'Key Legislation', link: '/research/key-legislation' }
        ],
      },
      {
        title: 'Training',
        link: '/training',
        items: [
          { text: 'Online Tutorials', link: 'https://ncsacw.acf.hhs.gov/tutorials/Default.aspx' },
          { text: 'Child Welfare Training Toolkit', link: '/training/toolkit' },
          { text: 'Videos and Webinars', link: '/training/videos-and-webinars' }
        ],
      },
      {
        title: 'Technical Assistance',
        link: '/technical',
        items: [
          {
            text: 'Family-Centered Systems Innovation Team',
            link: '/technical/idta',
          },
          {
            text: 'Regional Partnership Grant Program',
            link: '/technical/rpg',
          },
        ],
      },
      {
        title: 'About Us',
        link: '/about-us',
        items: [{ text: 'About Us', link: '/about-us' }],
      },
    ],

    /**
     * Search.gov configuration
     *
     * 1. Create an account with Search.gov https://search.usa.gov/signup
     * 2. Add a new site.
     * 3. Add your site/affiliate name here.
     */
    searchgov: {
      // Only change this if you're using a CNAME. Learn more here: https://search.gov/manual/cname.html
      endpoint: 'https://search.usa.gov',

      // Replace this with your search.gov site handle.
      affiliate: 'ncsacw-acf',

      // Replace this with your access key.
      access_key: 'rxtyEl2tSlk5oKuRA6mxseBdkscJdGIQyKai7DUoeSE=',

      // This renders the results within the page instead of sending to user to search.gov.
      inline: true,

      // This allows Search.gov to present relevant type-ahead search suggestions in your website's search box.
      // If you do not want to present search suggestions, set this value to false.
      suggestions: true,
    },

    /**
     * Digital Analytics Program (DAP) configuration
     *
     * USAID   - Agency for International Development
     * USDA    - Department of Agriculture
     * DOC     - Department of Commerce
     * DOD     - Department of Defense
     * ED      - Department of Education
     * DOE     - Department of Energy
     * HHS     - Department of Health and Human Services
     * DHS     - Department of Homeland Security
     * HUD     - Department of Housing and Urban Development
     * DOJ     - Department of Justice
     * DOL     - Department of Labor
     * DOS     - Department of State
     * DOI     - Department of the Interior
     * TREAS   - Department of the Treasury
     * DOT     - Department of Transportation
     * VA      - Department of Veterans Affairs
     * EPA     - Environmental Protection Agency
     * EOP     - Executive Office of the President
     * GSA     - General Services Administration
     * NASA    - National Aeronautics and Space Administration
     * NARA    - National Archives and Records Administration
     * NSF     - National Science Foundation
     * NRC     - Nuclear Regulatory Commission
     * OPM     - Office of Personnel Management
     * USPS    - Postal Service
     * SBA     - Small Business Administration
     * SSA     - Social Security Administration
     */
    dap: {
      // agency: 'your-agency',
      // Optional
      // subagency: 'your-subagency',
    },

    /**
     * Google Analytics configuration
     */
    ga: {
       ua: 'GTM-TMDHTLQ',
    },
  },
  pathPrefix: process.env.BASEURL || '/',
  plugins: [
    {
      resolve: 'gatsby-source-strapi',
      options: strapiConfig,
    },
    'gatsby-plugin-postcss',
    'gatsby-plugin-image',
    'gatsby-plugin-sharp',
    'gatsby-transformer-sharp',
    'gatsby-plugin-root-import',
    'gatsby-plugin-sass',
    `gatsby-plugin-styled-components`,
    {
      resolve: `gatsby-plugin-schema-snapshot`,
      options: {
        // Path where the type definitions will be saved to
        path: `src/schema/schema.gql`,
        // ensure all field types are included
        // don't turn this off unless you have a very good reason to
        withFieldTypes: true,
        // manually control if a saved schema snapshot should be replaced with an
        // updated version
        update: false,
      },
    },
    {
      resolve: `gatsby-transformer-json`,
      options: {
        typeName: ({ node }) => {
          return node.relativePath.split('.').slice(0, -1).join('.')
        }
      }
    },
    // {
    //   resolve: 'gatsby-plugin-sass'
    // },
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-breadcrumb',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'blog-posts',
        path: `${__dirname}/src/blog-posts`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'documentation-pages',
        path: `${__dirname}/src/documentation-pages`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'data',
        path: `${__dirname}/src/data`,
      },
    },
    'gatsby-transformer-remark',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'gatsby-starter-default',
        short_name: 'starter',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#fff',
        display: 'minimal-ui',
        icon: 'src/assets/images/favicon.png', // This path is relative to the root of the site.
      },
    },
    'gatsby-plugin-sitemap',
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: 'https://ncsacw.acf.hhs.gov',
         sitemap: 'https://ncsacw.acf.hhs.gov/sitemap/sitemap-0.xml',
         policy: [{ userAgent: '*', allow: '/' }]
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // 'gatsby-plugin-offline',
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /\.inline\.svg$/,
        },
      },
    },
    `gatsby-plugin-meta-redirect`
  ],
};
